import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
export const _frontmatter = {
  "title": "Grid Tile Layouts with auto-fit and minmax",
  "date": "2018-06-18",
  "promo": "grids",
  "description": "How to create perfect tile layouts with CSS Grid",
  "color": "#41dcff"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tile layouts are probably the most common layout we build as frontend devs. Especially for content areas. And they've never been easier to create thanks to the new `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` and `}<inlineCode parentName="p">{`auto-fit`}</inlineCode>{` magic that comes with CSS Grid. Lemme show you the new & easy way to make perfect, responsive tile layouts with CSS Grid.`}</p>
    <h2>{`Tiles Everywhere`}</h2>
    <p>{`You might recognize some of these. Pretty much all the pages in `}<strong parentName="p">{`Google Music`}</strong>{` are tile layouts:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/new-releases.jpg",
        "alt": "google music layout"
      }}></img></p>
    <p>{`Side note: is it just me or are so many new releases terrible? It's like they just gave up. I mean one of the bands is `}<em parentName="p">{`literally`}</em>{` named `}<em parentName="p">{`Garbage`}</em>{`.`}</p>
    <p>{`Anyway, many blogs like mine present posts in tile format:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/blog-tiles.jpg",
        "alt": "blog post tile layout"
      }}></img></p>
    <p>{`You've probably never seen the Twitter `}<em parentName="p">{`moments`}</em>{` page cause it's worthless, but it's also just a tile layout where some grid items span more cells:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/twitter-moments.jpg",
        "alt": "twitter moments tile layout"
      }}></img></p>
    <p>{`You get the idea. Tile layouts are `}<em parentName="p">{`everywhere`}</em>{`.`}</p>
    <h2>{`auto-fit was Made for This`}</h2>
    <p>{`We use the new `}<inlineCode parentName="p">{`auto-fit`}</inlineCode>{` keyword to create tile layouts. It instructs the Grid to create as many columns of the given size as can fit in the space of the grid container.`}</p>
    <p>{`So say we want to divide our Grid into as many `}<inlineCode parentName="p">{`200px`}</inlineCode>{` columns as can fit in the space:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.app {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 200px);
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/auto-fit-200.jpg",
        "alt": "CSS Grid auto-fit repeat"
      }}></img></p>
    <p>{`There was enough room in this `}<inlineCode parentName="p">{`1400px`}</inlineCode>{` div to create `}<em parentName="p">{`six`}</em>{` `}<inlineCode parentName="p">{`200px`}</inlineCode>{` columns with `}<inlineCode parentName="p">{`15px`}</inlineCode>{` column gaps between them.`}</p>
    <p>{`You could call that good enough and ship it at this point and your tile layout would be as good as many on the interwebs. But look at the right side of our Grid - there's a big empty area that doesn't look too great — where there wasn't enough room for another column.`}</p>
    <p>{`CSS Grid is well equipped to help us make this tile layout even better.`}</p>
    <h2>{`minmax() Has your Back`}</h2>
    <p>{`We can adjust our tile layout to make our tiles fill up all the space in the Grid rather than leaving empty room at the end.`}</p>
    <p>{`We do that by creating `}<em parentName="p">{`flexible`}</em>{` columns with `}<inlineCode parentName="p">{`minmax`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.app {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/minmax-tiles.jpg",
        "alt": "flexible minmax columns"
      }}></img></p>
    <p>{`No more wasted space on the right!`}</p>
    <p><strong parentName="p">{`How the devil did that work?`}</strong></p>
    <p><inlineCode parentName="p">{`minmax(200px, 1fr)`}</inlineCode>{` made our columns start out at their minimum size of `}<inlineCode parentName="p">{`200px`}</inlineCode>{`. So the Grid created as many `}<inlineCode parentName="p">{`200px`}</inlineCode>{` as could fit — six. Leaving roughly `}<inlineCode parentName="p">{`130px`}</inlineCode>{` of free space remaining on the right just like before. But this time the Grid then evenly distributed that extra space to the six columns since their maxiumum size was set to `}<inlineCode parentName="p">{`1fr`}</inlineCode>{` (1 fraction of the free space).`}</p>
    <p>{`This combination of `}<inlineCode parentName="p">{`auto-fit`}</inlineCode>{` and `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` makes our tile layout both flexible and full-width.`}</p>
    <h2>{`Responsive Ready`}</h2>
    <p>{`The best part is - a tile layout built this way is already good to go on smaller mobile screens.`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/tiles.gif",
        "alt": "responsive grid tiles"
      }}></img></p>
    <p>{`Any time there's no longer room for a `}<inlineCode parentName="p">{`200px`}</inlineCode>{` column, the Grid automatically creates a new row and pushes items down into it. The maximum `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` value of `}<inlineCode parentName="p">{`1fr`}</inlineCode>{` ensures the items always fill the entire row. This is just one of the reasons why CSS Grid is so great for `}<a parentName="p" {...{
        "href": "/post/grid-for-responsive-layout/"
      }}>{`responsive layouts`}</a>{`.`}</p>
    <h2>{`Even Cooler Layouts with Tile Patterns`}</h2>
    <p>{`Now that you see how the basics of a Grid tile layout work, let's have some fun with it.`}</p>
    <p>{`When we use `}<inlineCode parentName="p">{`repeat()`}</inlineCode>{` we can have it repeat not just one track, but a `}<em parentName="p">{`pattern`}</em>{` of tracks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.app {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr) 150px);
}
`}</code></pre>
    <p>{`This time we've told our Grid to repeat as many sets as can fit, where a set consists of a flexible `}<inlineCode parentName="p">{`300px`}</inlineCode>{` column plus a fixed `}<inlineCode parentName="p">{`150px`}</inlineCode>{` column.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/minmax-pattern.jpg",
        "alt": "minmax pattern"
      }}></img></p>
    <p>{`This layout worked the same way as before except this time only the flexible `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` columns scored some of the extra space after the `}<inlineCode parentName="p">{`auto-fit`}</inlineCode>{` algorithm did its thing.`}</p>
    <h2>{`Vary Them Tiles`}</h2>
    <p>{`I mentioned earlier that the Twitter `}<em parentName="p">{`moments`}</em>{` page is just a tile layout with some grid items spanning more cells. Let's do something similar and make every third grid item span two columns, and every fifth one span two rows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.item:nth-of-type(3n) {
  grid-column: span 2;
}

.item:nth-of-type(5n) {
  grid-row: span 2;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/tile-variation.jpg",
        "alt": "tile variation"
      }}></img></p>
    <p>{`The result is pretty cool, but would look even better if we used the `}<strong parentName="p">{`dense`}</strong>{` `}<a parentName="p" {...{
        "href": "/post/grid-item-placement/"
      }}>{`grid item placement`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.app {
  grid-auto-flow: dense;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/dense-tiles.jpg",
        "alt": "dense grid tiles"
      }}></img></p>
    <p>{`This caused the grid to return to previously skipped cells and pack the items in as densly as possibly.`}</p>
    <h2>{`Now for a Practical Example`}</h2>
    <p>{`Google Music, Twitter, and blogs layouts are fine. But let's see this in action for something far more inspiring: a photo gallery of `}<a parentName="p" {...{
        "href": "/post/solve-it-once/"
      }}>{`Sam`}</a>{` — the best dog in the world.`}</p>
    <p>{`One new bit I'll add for this is to use `}<inlineCode parentName="p">{`grid-auto-rows`}</inlineCode>{` to make all the rows that `}<inlineCode parentName="p">{`auto-fit`}</inlineCode>{` generates be `}<inlineCode parentName="p">{`200px`}</inlineCode>{` tall.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.gallery {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}

.wide {
  grid-column: span 2;
}

.tall {
  grid-row: span 2;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/sam-gallery.jpg",
        "alt": "sam grid tile gallery"
      }}></img></p>
    <p>{`Awww what a good little beast. She looks even more adorbs in a tile layout.`}</p>
    <h2>{`If You Only Knew the Power of the Grid`}</h2>
    <p>{`CSS Grid is fantastic and we're only beginning to discover what we can do with it. I'm loving it for `}<a parentName="p" {...{
        "href": "/post/overlapping-grid-items/"
      }}>{`magazine layouts`}</a>{`, `}<a parentName="p" {...{
        "href": "/post/new-grid-site/"
      }}>{`game sites`}</a>{`, and even the games themselves that I'm building.`}</p>
    <p>{`I consider tile layouts a solved problem with CSS Grid. Here's a code `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/eKyEOM"
      }}>{`example`}</a>{` you can go play with.`}</p>
    <p>{`The next time you need a tile layout, CSS Grid `}<inlineCode parentName="p">{`auto-fit`}</inlineCode>{` & `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` has got you covered!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      